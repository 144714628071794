export const toIsoDate = (value) => {
  if (!value) return "0001-01-01T00:00:00.000Z"

  if (value.split("/").length > 1 && value.length === 10) {
    const instances = value.split("/")
    const [day, month, year] = instances

    return `${year}-${month}-${day}T00:00:00.000Z`
  }

  return value
}

export const toDate = (value) => {
  if (!value) return ""

  const date = value.split("T").length ? value.split("T")[0] : value

  if (date.split("-").length > 1 && date.length === 10) {
    const instances = date.split("-")
    const [year, month, day] = instances
    const formattedDate = `${day}/${month}/${year}`
    if (formattedDate === "01/01/0001") return ""
    return formattedDate
  }

  return date
}
