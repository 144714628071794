// Utils
import { removeWhiteSpaces } from "./string"

export const toPhone = (value) => {
  if (!value) return
  if (value.length === 11) return toCellphone(value)
  if (value.length === 10) return toLandline(value)
  return value
}

const toCellphone = (value) => {
  if (!value) return
  return removeWhiteSpaces(value)?.replace(
    /(\d{2})(\d{5})(\d{4})/,
    "($1) $2-$3",
  )
}
const toLandline = (value) => {
  if (!value) return
  return removeWhiteSpaces(value)?.replace(
    /(\d{2})(\d{4})(\d{4})/,
    "($1) $2-$3",
  )
}

export function hiddenPhoneMask(phone = "") {
  const phoneUnformatted = (phone || "").replace(/[^\d]+/g, "")
  phoneUnformatted.replace("55", "")

  if (!phoneUnformatted) {
    return ""
  }

  if (phoneUnformatted.length === 11) {
    return phoneUnformatted.replace(/(\d{2})(\d{5})(\d{4})/, "($1) *****-$3")
  }

  return phoneUnformatted.replace(/(\d{2})(\d{4})(\d{4})/, "($1) ****-$3")
}
