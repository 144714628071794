const documentIsSet = () => {
  return typeof window !== "undefined" && window.document !== undefined
}

export const getCookie = (cname) => {
  if (!documentIsSet()) {
    return ""
  }

  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  let cookieList = decodedCookie.split(";")
  for (let i = 0; i < cookieList.length; i++) {
    let singleCookie = cookieList[i]
    while (singleCookie.charAt(0) === " ") {
      singleCookie = singleCookie.substring(1)
    }
    if (singleCookie.indexOf(name) === 0) {
      return singleCookie.substring(name.length, singleCookie.length)
    }
  }
  return ""
}

export const setCookie = (name, value, exdays) => {
  const date = new Date()
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`

  if (!documentIsSet()) return

  document.cookie = `${name}=${value};${expires};path=/`
}
