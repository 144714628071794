const numberFormatter = ({
  style = "decimal",
  value = 0,
  currency = "BRL",
  decimals = 2,
  currencyDisplay = "symbol",
}) => {
  if (!value) return
  return Intl.NumberFormat("pt-BR", {
    style,
    currency,
    currencyDisplay,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value)
}

export const toCurrency = (value, options = {}) => {
  if (!value) return
  return numberFormatter({ value, style: "currency", ...options })
}

export const currencyToFloat = (value) => {
  const previewValue = value.replace(/\.|\,/g, "")
  return Number(previewValue.replace(/(\d{1,})(\d{2})/, "$1.$2"))
}

export const floatToDisplayableNumber = (value) => {
  return Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}
