export const yearsToMonths = (value) => Number(value) * 12

export const monthsToYears = (value) => value / 12

export const formattedMillisecondsToMinutes = (milliseconds) => {
  return Math.floor((milliseconds / 1000 / 60) % 60)
}

export const formattedMillisecondsToSeconds = (milliseconds) => {
  return Math.floor((milliseconds / 1000) % 60)
}
