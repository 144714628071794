import ga from "@mobi/libraries/analytics/ga"
import segment from "@mobi/libraries/analytics/segment"
import isClient from "@mobi/utils/browser/isClient"

import { getLeadUuidFromToken } from "../js/analytics"
import { getItem } from "../js/clientStorage"

const appTrackers = {
  trackSegmentPage: ({ event = "", data = null }) => {
    const eq3 = getItem("eq3_uuid")
    const eq3_uuid = eq3 && eq3 != "undefined" ? eq3 : null
    if (data) {
      segment.page(event, {
        ...data,
        lead_uuid: getLeadUuidFromToken(),
        ...(eq3_uuid && { eq3_uuid }),
      })
      return
    }
    segment.page(event, {
      lead_uuid: getLeadUuidFromToken(),
      ...(eq3_uuid && { eq3_uuid }),
    })
  },

  trackSegmentEvent: ({ event = "", data = null }) => {
    const eq3 = getItem("eq3_uuid")
    const eq3_uuid = eq3 && eq3 != "undefined" ? eq3 : null
    if (data) {
      segment.track(event, {
        ...data,
        lead_uuid: getLeadUuidFromToken(),
        ...(eq3_uuid && { eq3_uuid }),
      })
      return
    }
    segment.track(event, {
      lead_uuid: getLeadUuidFromToken(),
      ...(eq3_uuid && { eq3_uuid }),
    })
  },

  trackGaPage: (payload) => {
    ga.page(payload)
  },

  trackGaEvent: (payload) => {
    ga.track(payload)
  },

  trackGaDefaultParameters: (payload) => {
    ga.defaultParameters(payload)
  },

  setGaUserProperties: (payload) => {
    ga.setUserProperties(payload)
  },

  segmentVWOTest: ({ experimentId, variationName, pageName }) => {
    const payload = {
      pageName,
      experimentId,
      variationName,
      lead_uuid: getLeadUuidFromToken(),
      vwoUserId: isClient() && window?._vwo_uuid,
    }
    segment.track("VWO Test", payload)
  },
}

export default appTrackers
