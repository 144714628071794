import * as currency from "./currency"
import * as date from "./date"
import * as documents from "./documents"
import * as number from "./number"
import * as phone from "./phone"
import * as services from "./services"
import * as string from "./string"
import * as time from "./time"

// TODO: importar e exportar um por um para resolver tree shaking
export default {
  ...currency,
  ...date,
  ...documents,
  ...number,
  ...phone,
  ...services,
  ...string,
  ...time,
}
