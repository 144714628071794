import isClient from "@mobi/utils/browser/isClient"

import { getItem } from "../js/clientStorage"
import appTrackers from "./appTrackers"

export const trackAbTest = ({ experimentId, variant, page }) => {
  if (!isClient()) return

  // Não enviar evento caso o teste não esteja ativo
  if (!window._vwo_exp[experimentId]) return

  appTrackers.segmentVWOTest({
    experimentId,
    variationName: variant,
    pageName: page,
  })
}

export const trackPageview = ({ ga, ga4, segment }) => {
  ga && appTrackers.trackGaPage(mountGaPageviewObject(ga))
  ga4 && appTrackers.trackGaPage(mountGa4PageviewObject(ga4))
  segment && appTrackers.trackSegmentPage(mountSegmentEvent(segment))
}

export const trackEvent = ({ ga, ga4, segment }) => {
  ga && appTrackers.trackGaEvent(mountGaEventObject(ga))
  ga4 && appTrackers.trackGaEvent(mountGa4EventObject(ga4))
  segment && appTrackers.trackSegmentEvent(mountSegmentEvent(segment))
}

export const trackDefaultParameters = ({ ga4 }) => {
  ga4 && appTrackers.trackGaDefaultParameters(mountGa4DefaultParameters(ga4))
}

export const addTrackPageViewOnScroll = ({
  elementId,
  threshold = 0.8,
  trackObject,
}) => {
  if (!elementId) return

  const element = document.querySelector(elementId)
  if (!element) return

  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        trackPageview(trackObject)
        observer.unobserve(entry.target)
      }
    })
  }

  const observer = new IntersectionObserver(callback, { threshold })

  observer.observe(element)
}

const mountGaPageviewObject = ({
  business = "PF",
  channel = "Web",
  rule = "pageLoad",
  label,
  hashedCpf,
  proposalId,
  ...additional
}) => {
  const eq3 = getItem("eq3_uuid")
  return {
    site: { negocio: business, tipoDeCanal: channel },
    page: { nome: label },
    rule,
    ...(eq3 && eq3 != "undefined" && { eq3 }),
    ...(hashedCpf && { visitor: { partnerid: hashedCpf } }),
    ...(proposalId && { custom: { iDPropostaRedVentures: proposalId } }),
    ...(additional && additional),
  }
}

const mountGaEventObject = ({
  category = "Clique",
  action = "Simulador",
  rule = "customLink",
  noIteraction = false,
  label,
  hashedCpf,
  ...additional
}) => {
  const eq3 = getItem("eq3_uuid")
  return {
    events: { category, action, label, noIteraction },
    rule,
    ...(eq3 && eq3 != "undefined" && { eq3 }),
    ...(hashedCpf && { visitor: { partnerid: hashedCpf } }),
    ...(additional && additional),
  }
}

const mountGa4DefaultParameters = ({
  business,
  environment,
  businessChannel,
}) => {
  return {
    parameters: {
      business,
      environment,
      business_channel: businessChannel,
    },
  }
}

const mountGa4PageviewObject = ({
  eventName = "screen_view",
  implementationTeam,
  hashedCpf,
  proposalId,
  customPath,
}) => {
  const eq3 = getItem("eq3_uuid")
  return {
    event_name: eventName,
    parameters: {
      custom_path: customPath,
      implementation_team: implementationTeam,
      ...(hashedCpf && { customer_id: hashedCpf }),
      ...(proposalId && { proposal_id: proposalId }),
      ...(eq3 && eq3 != "undefined" && { eq3 }),
    },
  }
}

const mountGa4EventObject = ({
  implementationTeam,
  eventName,
  hashedCpf,
  proposalId,
  customPath,
  detail,
  isSoftLead,
  description,
}) => {
  return {
    event_name: eventName,
    parameters: {
      custom_path: customPath,
      implementation_team: implementationTeam,
      ...(detail && { detail }),
      ...(hashedCpf && { customer_id: hashedCpf }),
      ...(proposalId && { proposal_id: proposalId }),
      ...(description && { description }),
    },
    ...(isSoftLead && {
      user: {
        pf_id: hashedCpf,
      },
    }),
  }
}

const mountSegmentEvent = (payload) => {
  if (typeof payload === "string") return { event: payload }
  return payload
}
