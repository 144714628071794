// Utils
import removeSpecialChars from "@mobi/utils/formatters/removeSpecialChars"

// Formatters
import { currencyToFloat, floatToDisplayableNumber } from "./currency"
import { toDate, toIsoDate } from "./date"
import { toCnpj, toCpf } from "./documents"
import { toPhone } from "./phone"
import {
  boolToYesNo,
  removeWhiteSpaces,
  sanitizeText,
  toPostalCode,
  yesNoToBool,
} from "./string"
import { monthsToYears, yearsToMonths } from "./time"

export const selectLabelToValue = (label, options) => {
  const optionObject = options.find((option) => option.label === label)
  if (!optionObject) return ""
  return optionObject.value
}

export const selectValueToLabel = (value, options) => {
  const optionObject = options.find((option) => option.value === value)
  if (!optionObject) return ""
  return optionObject.label
}

// TODO: repensar nesse nome para algo mais explicativo
export const sendFormatters = {
  cpf: ({ value }) => removeSpecialChars(value),
  cnpj: ({ value }) => removeSpecialChars(value),
  date: ({ value }) => toIsoDate(value),
  phone: ({ value }) => removeSpecialChars(removeWhiteSpaces(value)),
  month: ({ value }) => yearsToMonths(value),
  quantity: ({ value }) => Number(value),
  bool: ({ value }) => yesNoToBool(value),
  cep: ({ value }) => removeSpecialChars(value),
  currency: ({ value }) => currencyToFloat(value),
  text: ({ value }) => sanitizeText(value),
  select: ({ value, options }) => selectLabelToValue(value, options),
}

export const fetchFormatters = {
  cpf: ({ value }) => toCpf(value),
  cnpj: ({ value }) => toCnpj(value),
  date: ({ value }) => toDate(value),
  phone: ({ value }) => toPhone(value),
  bool: ({ value, accents }) => boolToYesNo(value, accents),
  cep: ({ value }) => toPostalCode(value),
  displayableNumber: ({ value }) => floatToDisplayableNumber(value),
  year: ({ value }) => monthsToYears(value),
  select: ({ value, options }) => selectValueToLabel(value, options),
  ariaSelect: ({ value, options }) => selectValueToLabel(value, options),
}
