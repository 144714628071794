import { hasValidValue } from "./"

export const flatten = (obj) => {
	var flattenedObject = {}
	traverseAndFlatten(obj, flattenedObject)
	return flattenedObject
}

const traverseAndFlatten = (currentNode, target) => {
	for (var key in currentNode) {
		if (currentNode.hasOwnProperty(key)) {
			var value = currentNode[key]
			if (typeof value === "object" && !Array.isArray(value)) {
				traverseAndFlatten(value, target)
			} else {
				if (hasValidValue(value)) target[key] = value
			}
		}
	}
}
