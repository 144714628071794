import {
  ENVIRONMENT,
  GIT_VERSION,
  IS_DATADOG_ENABLED,
} from "gatsby-env-variables"

import { datadogRum } from "@datadog/browser-rum"

const init = () => {
  if (!IS_DATADOG_ENABLED) return

  const version = {
    ...(ENVIRONMENT === "production" ? { version: GIT_VERSION } : {}),
  }

  datadogRum.init({
    applicationId: "34390218-feae-4c7e-9a1d-a34146ea6bda",
    clientToken: "pub1e5eeb671b57b2a23aab228a0b20d002",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.com",
    service: "loans",
    env: `${ENVIRONMENT}`,
    ...version,
    sessionSampleRate: 0,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    actionNameAttribute: "datadog-action",
  })
}

export default {
  init,
}
