import { getItem } from "@garantidos/utils"

import { getUrlParams } from "."
import { getCookie } from "../js/cookies"
import jwt_decode from "jwt-decode"

export const getLeadUuidFromToken = () => {
  if (!getItem("appToken")) return
  const { token } = JSON.parse(getItem("appToken"))
  const { data = null } = jwt_decode(token) ?? {}
  const { uuid = "" } = JSON.parse(data) ?? {}
  return uuid
}

export const getAnonymousId = () => {
  const segmentId =
    window?.analytics?.user && window.analytics?.user()?.anonymousId()
  if (segmentId) return segmentId
  const cohesionId = window?._Cohesion?.anonymousId
  if (cohesionId) return cohesionId
  return ""
}

export const analytics = () => {
  const googleId = getCookie("_ga")
  const personId = getUrlParams("mp_uuid")
  const { latitude, longitude } = JSON.parse(getItem("appGeoloc")) ?? {
    latitude: 0,
    longitude: 0,
  }

  return {
    person_uuid: personId,
    source_url: location.href,
    referrer_url: document.referrer,
    user_agent: navigator.userAgent,
    google_id: getGoogleIdFormatted(googleId),
    facebook_click_id: getCookie("_fbc"),
    facebook_browser_id: getCookie("_fbp"),
    anonymous_id: getAnonymousId(),
    latitude: latitude.toString(),
    longitude: longitude.toString(),
  }
}

const getGoogleIdFormatted = (cookie) => {
  if (!cookie) return ""
  const cookieSplitted = cookie.split(".")
  return `${cookieSplitted[2]}.${cookieSplitted[3]}`
}
