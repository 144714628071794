import { tokens } from "gatsby-env-variables"

export const loadScriptCaptcha = () => {
	const sitekey = tokens.RECAPTCHA
	const script = document.createElement("script")
	script.src = `https://www.google.com/recaptcha/api.js?render=${sitekey}`
	document.body.appendChild(script)
}

export const executeRecaptcha = async () => {
	return await grecaptcha.execute(tokens.RECAPTCHA, { action: "submit" })
}
