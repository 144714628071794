export const removeWhiteSpaces = (value) => value.replace(/\s+/g, "")

export const toTextMonths = (value) => {
  const sufix = value > 1 ? "meses" : "mês"
  return `${value} ${sufix}`
}

export const toTextYears = (value) => {
  const valueInYears = value / 12
  const sufix = valueInYears > 1 ? "anos" : "ano"
  return `${valueInYears} ${sufix}`
}

export const removeCharsExceptNumbers = (value) => value.replace(/[^0-9.]/g, "")

export const sanitizeText = (value) =>
  value
    ?.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ\s]+/gim, "")
    .replace(/\s\s+/g, " ")
    .trim()

export const numberToStringWithDots = (value) =>
  (value || "")
    .toString()
    .replaceAll(".", "")
    .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ".")

export const boolToYesNo = (value, accents) => {
  if (value === null || value === undefined) return
  if (accents) return value ? "Sim" : "Não"
  return value ? "sim" : "nao"
}

export const yesNoToBool = (value) => value === "sim"

export const toPostalCode = (value) =>
  value?.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3")

export const formatProposalNumber = (number = 0) =>
  String(number).padStart(8, "0")
