import { getItem, setItem } from "@garantidos/utils"

const saveCurrentPosition = () => {
  if (!getItem("appGeoloc")) {
    navigator.geolocation.getCurrentPosition((position) => {
      setItem("appGeoloc", {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
    })
  }
}

export { saveCurrentPosition }
